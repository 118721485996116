.create-component-buttons {
    .btn {
        border-radius: 6px;
        padding: 20px 25px;
    }
    .spinner-border {
        width: 20px !important;
        height: 20px !important;
        border-width: 3px !important;
    }
}