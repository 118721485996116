.card-connector {
    right: 10px;
    top: -54px;
    .circle {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        top: 1px;
        &:last-of-type {
            top: -1px
        }
    }
    .line {
        width: 4px;
        height: 54px;
        margin-left: 4px;
    }
}