$newBackgroundDark: #242630;

.adid-manager-edit-content {
    .form-control.border {
        height: 64px;
    }
    .table-responsive {
        thead {
            background-color: #fff !important;
            border-bottom: 2px solid $newBackgroundDark;
        }
        tbody {
            tr {
                .value {
                    max-width: 300px;
                    width: 40%;
                    textarea {
                        max-height: 400px;
                        min-height: 50px;
                        outline: none;
                        font-size: 14px;
                    }
                }
                .form-group.resize {
                    textarea {
                        resize: none;
                    }
                }
                .edit-save-btn {
                    right: 0;
                }
                .form-group:hover {
                    .edit-save-btn.edit-btn {
                        width: 81px;
                        .save-text {
                            opacity: 1;
                            right: 35px;
                        }
                    }
                    .edit-save-btn.disabled-btn {
                        width: 35px;
                        .save-text {
                            opacity: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .editable-card {
        .card-body {
            .section:nth-of-type(1) {
                .custom-textarea {
                    .form-label {
                        background: #fff;
                        padding: 8px 10px 5px;
                        top: 2px;
                        left: 20px;
                    }
                    textarea {
                        height: 112px;
                    }
                }
            }
            .section:nth-of-type(4) {
                .col-12:first-of-type {
                    & > div {
                        margin-bottom: 2rem;
                    }
                }
                .text-danger {
                    width: 80%;
                    margin: auto;
                }
            }
        }
    }
    .editable-card:hover {
        .edit-save-btn.edit-btn {
            width: 35px;
            .save-text {
                opacity: 0;
                right: 0;
            }
        }
    }
}