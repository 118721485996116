.adid-manager-preview-content {
    .preview-iframe {
        border: none;
    }

    .hidden {
        display: none;
    }

    .is-mobile .embed-responsive {
        width: 360px;
        height: 640px;
    }
}
