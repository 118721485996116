$newBackgroundDark: #242630;

.multiple-editable-inputs {
    form {
        &:last-of-type {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
    .mobile-label {
        right: 30px;
        font-size: 11px;
    }
}
.mobile-input {
    form {
        .form-group {
            .form-label {
                display: none;
            }
            .form-control-plaintext {
                padding-top: 0;
            }
        }
    }
    .mobile-label {
        top: 25px;
    }
}
.mobile-input-edit {
    form {
        .form-group {
            .form-label {
                display: block;
                left: 21px;
                top: 15px;
            }
            .form-control {
                min-height: auto;
                height: auto;
                padding-top: 35px;
                padding-bottom: 15px;
                padding-left: 19px;
            }
        }
    }
    .mobile-label {
        right: 30px;
        top: 15px;
        font-size: 11px;
    }
}