$newBackgroundDark: #242630;

.results-dropdown {
    .dropdown.show {
        .dropdown-toggle {
            border: 1px solid #fff !important;
            z-index: 10000;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            transition: none !important;
            box-shadow: 0px 30px 40px #00000029 !important;
        }
    }
}