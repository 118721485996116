.adid-results-content {
    .dropdown.filter-style-dropdown {
        .dropdown-toggle {
            height: 48px;
            .fa-chevron-down {
                top: 16px;
            }
        }
    }
    .form-control.border {
        height: 48px;
    }
    .display-ads {
        padding-top: 100px !important;
        padding-bottom: 75px;
        @media (max-width: 1632px) {
            padding-top: 165px !important;
        }
        .editable-card {
            & > span {
                padding-top: 1.25rem !important;
            }
            .card-body {
                .item-set {
                    .col {
                        padding-right: 0;
                        .adId {
                            flex-wrap: wrap !important;
                        }
                    }
                }
            }
        }
    }
    .search-filters.position-fixed {
        z-index: 1000;
        margin-left: 288px;
        padding-right: 17%;
        left: 0;
        top: 63px;
        height: 100px;
        @media (max-width: 1632px) {
            height: 163px;
        }
        .dropdown-search {
            min-width: 350px;
            max-width: 500px;
            margin-right: 1rem;
        }
        .filters {
            @media (max-width: 1632px) {
                margin-top: 15px;
            }
            .btn.reset-filters {
                height: 48px;
                min-width: 130px;
            }
        }
    }
    .create-partner-btns {
        padding-right: 2rem;
        .btn {
            min-height: 48px;
        }
    }
    .column-label.position-fixed {
        z-index: 100;
        height: 50px;
        margin-left: 17%;
        padding-right: 17%;
        left: 0;
        top: 160px;
        @media (max-width: 1632px) {
            top: 225px;
        }
        .col-12 {
            padding-left: 35px;
            padding-right: 40px;
            p {
                margin-bottom: 0;
            }
        }
    }
    .top-adid-count-container.position-fixed {
        bottom: 0;
        height: 125px;
        .results-dropdown {
            .dropdown-menu {
                top: auto !important;
                padding-bottom: 0;
            }
        }
    }

    .z-top {
        z-index: 10000;
    }
}