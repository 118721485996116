.text-404 {
    font-size: 1.5rem;
    padding-top: 3rem;
    h1 { 
        font-size: 3rem;
        font-weight: 800;
    }
    p {
        font-size: 1rem;
    }
}