.item-set {
    .item {
        border: none;
        border-right: 1px solid rgba(#707070, .3);
        &:last-of-type {
            border-right: none;
        }
        .item-name {
            font-size: 14px;
            margin-bottom: 0 !important;
        }
        .item-name.text-truncate {
            max-width: 500px;
        }
        &.bin-description {
            width: 50%;
            max-width: 250px;
            margin-bottom: -10px;
            margin-top: -10px;

            p:nth-of-type(1) {
                padding-top: 10px;
            }

            p:nth-of-type(2) {
                overflow-x: scroll;
                text-overflow: inherit;
                padding-bottom: 10px;
            }
        }
    }
}