@tailwind base;
@tailwind components;
@tailwind utilities;

$newPrimary: #444CF8;
$newSecondary: #FCA311;
$newBackgroundDark: #242630;
$newBackgroundLight: #F8F9FD;
$newSuccess: #30DC9F;
$newDanger: #ED4F4F;

$yiq-text-dark: #525256;

$theme-colors: (
    primary: $newPrimary,
    secondary: $newSecondary,
    dark: $newBackgroundDark,
    light: $newBackgroundLight,
    success: $newSuccess,
    danger: $newDanger
);

$font-family-sans-serif: 'Roboto', sans-serif;
$text-muted: rgba($newBackgroundDark, .48);

@import "node_modules/bootstrap/scss/bootstrap";