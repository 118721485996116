$newBackgroundDark: #242630;

.custom-textarea {
    .form-label {
        color: $newBackgroundDark;
        top: 10px;
        left: 30px;
    }
    .form-control {
        padding-top: 30px;
    }
}