$BackgroundDark: #242630;

.editable-card {
    box-shadow: 0 0 40px rgba($BackgroundDark, .1);
}
.card-columns .editable-card {
    transform: translateZ(0);
}
.card-edit-mode {
    z-index: 1000;
}