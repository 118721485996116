$newSuccess: #30DC9F;
$newDanger: #ED4F4F;

.active-status {
    .active-descriptor {
        font-size: 12px;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .active-status-btn {
        width: 100px;
        font-size: 12px;
        color: #fff;
        overflow: hidden;
        .indicator {
            width: 22px;
            height: 22px;
            img {
                max-width: 10px;
            }
        }
    }
    
    .active-edit-btn {
        background-color: $newDanger;
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            animation: activeColorAnimate .3s linear 1;
            animation-fill-mode: forwards;
        }
        .active-text {
            margin-left: 5%;
            animation: activeTextAnimate .2s linear 1;
        }
        .indicator {
            background-color: darken($newSuccess, 10%);
            animation: activeCircleAnimate .3s linear 1;
            animation-fill-mode: forwards;
            img {
                margin-top: 8px;
            }
        }
    }

    .active-btn {
        left: 0px;
        background-color: $newSuccess;
        .active-text {
            margin-left: 5%;
        }
        .indicator {
            background-color: darken($newSuccess, 10%);
            left: 74%;
            img {
                margin-top: 8px;
            }
        }
    }
    
    .inactive-edit-btn {
        background-color: $newSuccess;
        &::before {
            content: "";
            position: absolute;
            border-radius: 10rem;
            width: 100%;
            height: 100%;
            animation: inactiveColorAnimate .3s linear 1;
            animation-fill-mode: forwards;
        }
        .active-text {
            margin-left: 35%;
            animation: inactiveTextAnimate .2s linear 1;
            animation-fill-mode: forwards;
        }
        .indicator {
            background-color: darken($newDanger, 25%);
            animation: inactiveCircleAnimate .3s linear 1;
            animation-fill-mode: forwards;
            img {
                margin-top: 6px;
            }
        }
    }

    .inactive-btn {
        right: 0px;
        background-color: $newDanger;
        .active-text {
            margin-left: 35%;
        }
        .indicator {
            background-color: darken($newDanger, 25%);
            left: 4%;
            img {
                margin-top: 6px;
            }
        }
    }
}

@keyframes activeColorAnimate {
    0% {
        top: 0;
        left: -100px;
        background-color: $newSuccess;
    }
    100% {
        left: 0px;
        background-color: $newSuccess;
    }
}

@keyframes inactiveColorAnimate {
    0% {
        top: 0;
        right: -100px;
        background-color: $newDanger;
    }
    100% {
        right: 0px;
        background-color: $newDanger;
    }
}

@keyframes activeCircleAnimate {
    0% {
        background-color: darken($newDanger, 25%);
        left: 4%;
    }
    10% {
        background-color: darken($newSuccess, 10%);
    }
    100% {
        background-color: darken($newSuccess, 10%);
        left: 74%;
    }
}

@keyframes inactiveCircleAnimate {
    0% {
        background-color: darken($newSuccess, 10%);
        left: 74%;
    }
    10% {
        background-color: darken($newDanger, 25%);
    }
    100% {
        background-color: darken($newDanger, 25%);
        left: 4%;
    }
}

@keyframes activeTextAnimate {
    0% {
        margin-left: 35%;
    }
    100% {
        margin-left: 5%;
    }
}

@keyframes inactiveTextAnimate {
    0% {
        margin-left: 5%;
    }
    100% {
        margin-left: 35%;
    }
}