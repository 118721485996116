$BackgroundDark: #242630;
$BackgroundLight: #F8F9FD;
$newDanger: #ED4F4F;

.mission-control-app {
    .border {
        border: 2px solid #d3d3d5 !important;
    }
    .error-border {
        border-color: $newDanger !important;
    }
    .text-small {
        font-size: 12px;
    }
    .top-nav {
        height: 70px;
        -webkit-box-shadow: 0 3px 20px rgba($BackgroundDark, 0.071); 
        box-shadow: 0 3px 20px rgba($BackgroundDark, 0.071);
        z-index: 100;
        width: 100%;
            .top-nav-logo {
            // border-color: rgba(255, 255, 255, 0.14) !important;
            border-bottom: 0 !important;
            img {
                max-height: 40px;
                width: 100%;
            }
        }
    }
    .main-section {
        // top: 70px;
        overflow-y: overlay;
        .sidebar-nav {
            position: fixed;
            z-index: 10;
            overflow: hidden;
            top: 0;
            bottom: 0;
            &:hover {
                overflow-y: overlay;
                padding-right: 15px;
            }
        }
        .main-content {
            margin-left: 0;
            // margin is 0px for max-width 768px
            @media (min-width: 1023px) {
                margin-left: 288px;
            }
            // margin-left: 16.6666666667%;
            padding-bottom: 100px;
            .spinner-border {
                width: 50px;
                height: 50px;
                border-width: 5px;
            }
        }
    }
    .opacity-translate-animation-enter {
        opacity: 0;
        transform: translateY(-15%);
    }
    .opacity-translate-animation-enter-active {
        opacity: 1;
        transform: translateY(0%);
        transition: all .3s ease;
    }
    .opacity-translate-animation-exit {
        opacity: 1;
        transform: translateY(0%);
    }
    .opacity-translate-animation-exit-active {
        opacity: 0;
        transform: translateY(-15%);
        transition: all .3s ease;
    }
    .routes-animation-enter {
        opacity: 0;
        transform: translateY(-5%);
    }
    .routes-animation-enter-active {
        opacity: 1;
        transform: translateY(0%);
        transition: all .3s ease-in-out;
    }
    .routes-animation-exit {
        opacity: 1;
        transform: translateY(0%);
    }
    .routes-animation-exit-active {
        opacity: 0;
        transform: translateY(-5%);
        transition: all .3s ease-in-out;
    }
    .width-30 {
        width: 30%;
    }
    .width-22Point5 {
        width: 22.5%;
    }
    .width-20 {
        width: 20%;
    }
    .width-17Point5 {
        width: 17.5%;
    }
    .width-15 {
        width: 15%;
    }
    .width-7Point5 {
        width: 7.5%;
    }
    ::placeholder {
        font-weight: lighter;
    }
}