$newBackgroundDark: #242630;

.editable-input {
    .form-group {
        position: relative;
        .form-label {
            top: 10px;
            left: 17px;
            color: $newBackgroundDark;
        }
        .form-control, .form-control-plaintext {
            min-height: 64px;
            padding-top: 25px;
            padding-left: 15px;
        }
        .form-control-plaintext {
            border: transparent !important;
            padding-left: 0;
        }
    }
    .read-only {
        .form-label {
            top: 0;
            left: 0;
        }
        .form-control-plaintext {
            &:focus {
                outline: none;
            }
            padding-top: 20px;
            padding-bottom: 0;
            min-height: auto;
        }
    }
}