.editable-header {
    .small-heading {
        font-size: 14px;
    }
    h5 {
        .badge {
            top: -2px;
            color: #242630;
            padding-right: 40px !important;
            .clipboard-icon {
                top: 0;
                right: -23px;
                width: 50px;
                height: 31px;
                background: #242630;
                border-top-right-radius: 10rem;
                border-bottom-right-radius: 10rem;
                svg {
                    max-width: 20px;
                    margin-top: -1px;
                    margin-left: 12px;
                    background: transparent;
                }
            }
        }
        .copied-span {
            margin-left: 30px;
            transition: all .2s ease;
            opacity: 0;
        }
        .copied-span.fade {
            opacity: 1;
        }
    }
    .edit-save-btn {
        top: 10px;
    }
    .cancel-btn {
        top: 10px;
        right: 110px;
        height: 35px;
    }
}