$newPrimary: #444CF8;
$newBackgroundLight: #F8F9FD;
$newBackgroundDark: #242630;
$textMuted: rgba($newBackgroundDark, .48);

.date-picker-wrapper {
    border-radius: 6px;
    box-shadow: 0px 0px 40px #00000029;
    .rdrDateDisplayWrapper {
        color: $newBackgroundLight !important;
    }
    .rdrNextPrevButton, .rdrNextButton, .rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel, .rdrMonthAndYearPickers select:hover {
        background: $newBackgroundLight !important;
    }
    .rdrDateDisplay, .rdrDayActive, .rdrDayHovered, .rdrStartEdge, .rdrEndEdge, .rdrStaticRangeSelected, .rdrInRange {
        color: $newPrimary !important
    }
    .rdrStaticRangeLabel, .rdrMonthAndYearPickers select, .rdrInputRangeInput:focus, .rdrInputRangeInput:hover {
        color: $newBackgroundDark;
    }
    .rdrPprevButton i {
        border-color: transparent $newBackgroundDark transparent transparent;
    }
    .rdrNextButton i{
        border-color: transparent transparent transparent $newBackgroundDark;
    }
    .rdrDateDisplayItemActive input, .rdrMonthName, .rdrWeekDay, .rdrInputRangeInput {
        color: $textMuted;
    }
    .rdrStaticRange {
        border: none;
    }
    .rdrStaticRangeSelected {
        background-color: $newBackgroundLight;
    }
    .rdrDateDisplayWrapper {
        border-top-right-radius: 6px;
    }
    .rdrMonths {
        border-bottom-right-radius: 6px;
    }
    .rdrStaticRange:first-of-type {
        border-top-left-radius: 6px;
    }
    .rdrCalendarWrapper {
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
    }
    .rdrDefinedRangesWrapper {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}