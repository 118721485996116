$newBackgroundDark: #242630;
$newPrimary: #444CF8;

.text-editor {
    .rdw-editor-toolbar {
        border: 2px solid rgba($newBackgroundDark, .15);
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        margin-bottom: 0;
        padding: 7px 15px 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
        .rdw-inline-wrapper, .rdw-link-wrapper, .rdw-block-wrapper, .rdw-history-wrapper {
            margin-bottom: 7px;
        }
        .rdw-option-wrapper, .rdw-dropdown-wrapper, .rdw-option-disabled {
            border: 2px solid rgba($newBackgroundDark, .15);
            border-radius: 6px;
            width: 35px;
            height: 35px;
            &:hover {
                background-color: none;
                box-shadow: none;
                cursor: default;
            }
        }
        .rdw-option-wrapper {
            img {
                max-width: 12px;
            }
        }
        .rdw-option-wrapper[title="Monospace"] {
            width: 80px;
            img {
                max-width: 50px;
            }
        }
        .rdw-history-wrapper {
            .rdw-option-wrapper {
                img {
                    max-width: 14px;
                }
            }
        }
        .rdw-dropdown-wrapper {
            width: auto;
            max-width: 137px;
            .rdw-dropdown-selectedtext {
                padding: 0 10px;
            }
            .rdw-dropdown-carettoopen, .rdw-dropdown-carettoclose {
                border: none;
                background: url('../../Images/img-chevron.svg') center center no-repeat;
                background-size: contain;
                height: 14px;
                width: 14px;
                top: 30%;
                transition: all 0.2s ease-in;
                margin-right: -3px;
            }
            .rdw-dropdown-carettoclose {
                transform: rotate(180deg);
                transition: all 0.2s ease-in;
            }
        }
        .rdw-option-active {
            background-color: rgba($newBackgroundDark, .15);
            box-shadow: none;
        }
        .rdw-link-modal {
            box-shadow: 0 5px 10px rgba(0,0,0,.07);
            border-radius: 6px;
            border: none;
            height: auto;
            font-size: 14px;
            .rdw-link-modal-label {
                font-size: 14px;
            }
            .rdw-link-modal-input {
                border-radius: 4px;
                border: 2px solid rgba($newBackgroundDark, .15);
            }
            .rdw-link-modal-buttonsection {
                .rdw-link-modal-btn {
                    border-radius: 4px;
                 }
                .rdw-link-modal-btn:first-of-type {
                    background-color: $newPrimary;
                    color: #fff;
                    font-weight: bold;
                    &:hover {
                        box-shadow: none;
                        background-color: darken($newPrimary, 15%);
                    }
                }
                .rdw-link-modal-btn:last-of-type {
                    border-color: rgba($newBackgroundDark, .15);
                    &:hover {
                        background-color: rgba($newBackgroundDark, .15);
                        box-shadow: none;
                    }
                }
            }
        }
        .rdw-dropdown-optionwrapper {
            box-shadow: 0 5px 10px rgba(0,0,0,.07);
            border-radius: 6px;
            border: none;
            height: auto;
            font-size: 14px;
            width: 200px;
            overflow-y: hidden;
            top: 2px;
            &:hover {
                overflow-y: overlay;
            }
            li {
                padding: 15px;
                border-bottom: 1px solid rgba($newBackgroundDark, .15);
                &:last-of-type {
                    border: none;
                }
            }
        }
    }
    .edit-section {
        background-color: #F8F9FD;
        border: 2px solid rgba($newBackgroundDark, .15);
        border-top: none;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        min-height: 100px;
        padding: 15px;
        font-size: 14px;
        &:hover {
            cursor: default !important;
        }
        .DraftEditor-root {
            top: -10px;
            margin-bottom: -10px;
            opacity: .4;
            .public-DraftEditor-content {
                .public-DraftStyleDefault-block {
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.text-editor.edit-mode {
    .rdw-editor-toolbar {
        box-shadow: 0 5px 10px rgba(0,0,0,.07);
        transition: all 0.3s ease-in-out;
        position: relative;
        z-index: 10;
        pointer-events: inherit;
        .rdw-option-wrapper:hover {
            background-color: rgba($newBackgroundDark, .15);
            box-shadow: none;
            cursor: pointer;
        }
    }
    .edit-section {
        background-color: #fff;
        .DraftEditor-root {
            opacity: 1;
        }
    }
    .edit-section:hover, .edit-section *:hover {
        cursor: text !important;
    }
}