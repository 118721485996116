$newBackgroundDark: #242630;

.text-editor {
    .badge-btn {
        border: 2px solid rgba($newBackgroundDark, .15);
        border-radius: 6px;
        height: 35px;
        width: 80px;
        margin: 0 4px;
        margin-bottom: 7px;
        img {
            width: 50px;
            height: auto;
        }
    }
    .badge-btn.active {
        background-color: rgba(36, 38, 48, 0.15);
    }
}