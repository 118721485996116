.edit-save-btn {
    height: 35px;
    width: 35px;
    top: 0px;
    right: 10px;
    transition: all .2s ease-in-out;
    &:hover {
        cursor: pointer;
    }
    .edit-icon {
        right: 9px;
        top: 9px;
    }
    .save-text {
        opacity: 0;
        top: 7px;
        right: 0px;
        transition: all .2s ease;
    }
}
.edit-save-btn.disabled-btn {
    &:hover {
        cursor: default;
    }
}
.edit-save-btn.save-btn {
    width: 87px;
    .save-text {
        opacity: 1;
        right: 35px;
    }
}
.editable-card:hover {
    .edit-save-btn.edit-btn {
        width: 81px;
        .save-text {
            opacity: 1;
            right: 35px;
        }
    }
    .edit-save-btn.disabled-btn {
        width: 35px;
        .save-text {
            opacity: 0;
            right: 0;
        }
    }
}