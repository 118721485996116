.filter-buttons-wrapper {
    .filter-text {
        top: 10px;
    }
    .filter-buttons {
        .btn {
            img {
                width: 15px;
                height: 15px;
                margin-top: -1.5px;
            }
        }
    }
}