.set-buttons {
    .btn {
        height: 30px;
        width: 30px;
        border-radius: 100% !important;
        img {
            width: 50%;
            top: 6px;
            left: 7px;
        }
        .spinner-border {
            border-width: 2px !important;
            width: 15px !important;
            height: 15px !important;
            top: 6px;
            left: 7px;
        }
    }
    .btn-clone, .btn-preview {
        img {
            width: 55%;
        }
    }
}