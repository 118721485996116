$newBackgroundLight: #F8F9FD;

.billing-content {
    .search-input {
        max-width: 400px;
        .form-control {
            height: 66px;
        }
        ::placeholder {
            font-weight: lighter;
        }
    }
}
.billing-content.bin-profile {
    .item-set {
        padding-right: 87px;
        .item {
            padding: 0 4% !important;
            @media (min-width: 992px) {
                &:nth-of-type(2) {
                    min-width: 110px;
                }
            }
        }
    }
}
.billing-content.cascade-bypass {
    .sort-by {
        .dropdown-menu {
            min-width: 125px;
        }
    }
    .top-content {
        margin-top: -20px
    }
    .filters, .sort-by, .form-control {
        margin-top: 20px;
    }
    .collapse-btn {
        height: 35px;
        position: absolute;
        right: 15px;
        top: 0;
        min-width: 115px;
        .spinner-border {
            width: 20px !important;
            height: 20px !important;
            border-width: 2px !important;
        }
    }
    .item-set {
        .list-group {
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: -0.5rem;
            padding-right: 100px;
            .item {
                padding-left: 0.5rem !important;
                margin: 0.5rem 0.5rem 0 0;
                padding-right: 1rem !important;
                flex-grow: 0.1;
            }
        }
    }
    .data-table {
        tbody {
            tr:nth-child(even) {
                background-color: $newBackgroundLight;
            }
        }
    }
}

.bin-search {
    width: 430px;
}