.user-nav {
    .profile-img {
        width: 20px;
        margin-top: -3px;
        margin-right: 5px;
    }
    .user-dropdown {
        .dropdown-toggle {
            color: inherit;
            padding-right: 0;
            &:after {
                border: none;
                background: url('../../../Images/img-chevron.svg') no-repeat;
                background-size: contain;
                width: 14px;
                height: 14px;
                vertical-align: middle;
                margin-left: 10px;
                margin-top: -13px;
            }
        }     
        .dropdown-menu {
            width: 15em;
            border: none;
            top: 50px;
            right: 0;
            left: auto;
            padding: 20px 30px;
            -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.07);
            box-shadow: 0 10px 20px rgba(0,0,0,.07);
            .dropdown-item {
                &:hover {
                    background: none;
                    color: inherit;
                }
            }
        }
    }
}