$newBackgroundDark: #242630;

.editable-dropdown {
    .text-truncate {
        max-width: 800px;
    }
    .dropdown-toggle {
        height: 64px;
        padding-top: 25px;
        padding-left: 15px;
        color: #53555A;
        &:focus {
            box-shadow: none;
        }
        &:after {
            border: none;
        }
        .select-label {
            color: $newBackgroundDark;
            top: 10px;
            left: 15px;
        }
        .fa-chevron-down {
            right: 15px;
            top: 30px;
            color: $newBackgroundDark;
        }
        &:disabled {
            background-color: #e9ecef;
            opacity: 1;
        }
    }
    .no-border-bottom {
        border-bottom: 0;
    }
    .dropdown-menu {
        max-height: 300px;
        overflow: auto;
        a {
            padding-left: 15px;
            color: $newBackgroundDark;
            &:active {
                color: white
            }
        }
    }
    .dropdown-menu[x-placement=bottom-start] {
        border-top: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        top: -5px !important;
    }
    .dropdown-menu[x-placement=top-start] {
        border-bottom: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        top: auto !important;
        margin-bottom: -2px !important;
    }
}