.dropdown-search {
    .dropdown.filter-style-dropdown {
        .dropdown-toggle {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
    .form-control.border {
        border-left: none !important;
        border-radius: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-right: 80px;
        &:focus {
            box-shadow: none;
        }
    }
    .search-icon {
        width: 20px;
        right: 15px;
        top: 10px;
        z-index: 10000;
    }
    .clear-text {
        right: 50px;
        top: 10px;
        z-index: 10000;
    }
}