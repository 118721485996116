$BackgroundDark: #242630;

.login-component {
    transform: translate(0%, 70%);
    .card {
        box-shadow: 0 0 40px rgba($BackgroundDark, .1);
        border-radius: 6px;
        .card-header {
            padding-top: 50px;
        }
        .card-body {
            padding: 0px 50px 50px;
        }
    }
    .btn {
        padding: 20px 25px;
        font-size: 20px;
        .fa-google {
            font-size: 30px;
            left: 20px;
            top: 19px;
        }
    }
}