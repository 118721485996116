$newBackgroundDark: #242630;

.dropdown.filter-style-dropdown {
    .dropdown-toggle {
        height: 66px;
        padding-top: 6px;
        padding-left: 15px;
        color: #53555A;
        font-size: 12px;
        border-radius: 6px !important;
        &:focus {
            box-shadow: none !important;
        }
        &:after {
            border: none;
        }
        .fa-chevron-down {
            right: 15px;
            top: 25px;
            color: $newBackgroundDark;
        }
        &.search-page {
            height: 38px;
            width: 205px;
            .fa-chevron-down {
                right: 15px;
                top: 10px;
                color: $newBackgroundDark;
            }
        }
    }
    .dropdown-menu {
        box-shadow: 0px 0px 40px #00000029;
        border: none;
        border-radius: 6px;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        min-width: 100%;
        max-height: 300px;
        width: auto;
        overflow: overlay;
        a {
            padding-left: 15px;
            color: $newBackgroundDark;
            padding-right: 15px;
            &:active {
                color: white
            }
        }
    }
}