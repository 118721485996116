.multi-section-card {
    .main-header {
        h5 {
            width: calc(90%);
            @media (max-width: 1420px) {
                width: calc(75%);
            }
            @media (max-width: 1130px) {
                width: calc(60%);
            }
            span:nth-of-type(2) {
                @media (max-width: 1130px) {
                    white-space: normal !important;
                }
            }
        }
        .cancel-save-buttons {
            right: 15px;
            top: -40px;
        }
    }
    .section {
        .filter-group {
            right: 15px;
            top: -5px;
        }
        .text-danger {
            margin-top: -20px;
        }
    }
    .editable-dropdown {
        .dropdown-toggle {
            .selected-text {
                padding-top: 3px;
            }
        }
    }  
}